<template>
  <div class="home-block">
    <logo class="reg-logo"/>

    <div class="home-block-content">
      <ul>
        <router-link tag="li" :to="{name: 'CreateAccount', params: {type: 'individual'}}">
          <span><span class="color-yellow">Personal</span> account</span>
          <icon-next color="#fff" size="70"/>
        </router-link>
        <router-link tag="li" :to="{name: 'CreateAccount', params: {type: 'business'}}">
          <span><span class="color-yellow">Business</span> account</span>
          <icon-next color="#fff" size="70"/>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
import IconNext from "@/assets/icons/IconNext";
import Logo from "@/assets/images/Logo";
export default {
  name: 'Home',
  components: {
    Logo,
    IconNext
  }
}
</script>

<style lang="less">
.reg-logo {
  position: absolute;
  top: 40px;
  left: 50%;
  width: 150px;
  margin-left: -75px;
}

.home-block {
  display: grid;
  align-self: center;
  align-content: center;
  background: #19191C;

  width: 100vw;
  height: 100vh;

  &-content {
    max-width: 1280px;
    margin: 0 auto;
    font-size: 60px;
    padding: 0 40px;

    & > div {
      margin-bottom: 60px;
      font-weight: 700;
      color: #FDE46A;
    }

    ul {
      display: grid;
      grid-column-gap: 60px;
      grid-row-gap: 100px;
      grid-template-columns: 1fr 1fr;

      li {
        position: relative;
        cursor: pointer;
        padding-right: 81px;
        font-weight: 700;
        line-height: 1.2;
        color: #fff;

        border-bottom: 6px solid #FDE46A;
        padding-bottom: 25px;

        svg {
          position: absolute;
          top: 2px; right: 5px;
          margin-left: 20px;
          transform: rotate(270deg);
        }

        .color-yellow {
          color: #FDE46A;
        }

        &:hover {
          color: #FDE46A;
          transition: all 1s ease;

          svg {
            transition: all 1s ease;
            right: -5px;

            .arrow-svg {
              fill: #FDE46A
            }
          }
        }
      }
    }

    @media all and (max-width: 1023px) {
      font-size: 42px;

      ul {
        grid-template-columns: 1fr;
        grid-row-gap: 60px;
      }
    }
  }
}
</style>
